define('su-ui/routes/users/index', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, Ember, AuthenticatedRouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {
    model: function model() {
      var store = this.store;
      return Ember['default'].RSVP.hash({
        users: store.find('user')
      });
    },
    setupController: function setupController(controller, models) {
      this.send('setPageTitle', 'Users');
      this.send('setPageSubTitle', {
        title: 'Add User',
        action: 'users.add'
      });
      controller.set('users', models.users);
    }
  });

});