define('su-ui/adapters/hostdetail', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  var get = Ember['default'].get;

  exports['default'] = DS['default'].RESTAdapter.extend({
    host: 'https://api.serversup.net:8080',
    namespace: 'api',
    host_id: null,

    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      switch (requestType) {
        case 'find':
          return this.urlForFind(id, modelName, snapshot);
        case 'findAll':
          return this.urlForFindAll(modelName);
        case 'findQuery':
          this.set('host_id', get(id, 'host_id'));
          return this.urlForFindQuery(query, modelName, id);
        case 'findMany':
          return this.urlForFindMany(id, modelName, snapshot);
        case 'findHasMany':
          return this.urlForFindHasMany(id, modelName);
        case 'findBelongsTo':
          return this.urlForFindBelongsTo(id, modelName);
        case 'createRecord':
          this.set('host_id', snapshot.attr('host_id'));
          return this.urlForCreateRecord(modelName, snapshot);
        case 'updateRecord':
          return this.urlForUpdateRecord(id, modelName, snapshot);
        case 'deleteRecord':
          this.set('host_id', snapshot.get('host_id'));
          return this.urlForDeleteRecord(id, modelName, snapshot);
        default:
          return this._buildURL(modelName, id);
      }
    },
    _buildURL: function _buildURL(modelName, id) {
      var url = [];
      var host = get(this, 'host');
      var prefix = this.urlPrefix();
      var path;

      // Added to build a nested route for the API
      var host_id = this.get('host_id');
      if (host_id) {
        url.push('hosts/' + host_id);
        this.set('host_id', null);
      }

      if (modelName) {
        path = this.pathForType(modelName);
        if (path) {
          url.push(path);
        }
      }

      if (id) {
        url.push(encodeURIComponent(id));
      }
      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');
      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      return url;
    },

    ajaxError: function ajaxError(jqXHR) {
      var error = this._super(jqXHR);
      var jsonErrors, response;
      if (jqXHR) {
        if (jqXHR.status === 422) {
          response = Ember['default'].$.parseJSON(jqXHR.responseText);
          var errors = {};
          if (response.errors != null) {
            jsonErrors = response.errors;
            for (var i = 0; i < jsonErrors.length; i++) {
              Ember['default'].keys(jsonErrors[i]).forEach(function (key) {
                return errors[key] = jsonErrors[i][key];
              });
            }
          }
          return new DS['default'].InvalidError(errors);
        }
        if (jqXHR.status === 403) {
          noty({
            text: jqXHR.responseJSON.message,
            type: 'error',
            layout: 'topCenter',
            modal: true
          });
        }
        return error;
      } else {
        return error;
      }
    }
  });

});