define('su-ui/components/host-status', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    detail: function detail() {
      return "hello";
    }
  });

});