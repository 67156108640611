define('su-ui/controllers/login', ['exports', 'ember', 'simple-auth/mixins/unauthenticated-route-mixin'], function (exports, Ember, UnauthenticatedRouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(UnauthenticatedRouteMixin['default'], {
    remember_me: false,
    actions: {
      authenticate: function authenticate() {
        var credentials = this.getProperties('email', 'password', 'remember_me');
        this.get('session').authenticate('authenticator:custom', credentials).then(null, function () {});
      }
    }
  });

});