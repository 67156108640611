define('su-ui/adapters/application', ['exports', 'ember', 'ember-data'], function (exports, Ember, DS) {

  'use strict';

  exports['default'] = DS['default'].RESTAdapter.extend({
    host: 'https://api.serversup.net:8080',
    namespace: 'api',

    ajaxError: function ajaxError(jqXHR) {
      var error = this._super(jqXHR);
      var jsonErrors, response;
      if (jqXHR) {
        if (jqXHR.status === 422) {
          response = Ember['default'].$.parseJSON(jqXHR.responseText);
          var errors = {};
          if (response.errors != null) {
            jsonErrors = response.errors;
            for (var i = 0; i < jsonErrors.length; i++) {
              Ember['default'].keys(jsonErrors[i]).forEach(function (key) {
                return errors[key] = jsonErrors[i][key];
              });
            }
          }
          return new DS['default'].InvalidError(errors);
        }
        if (jqXHR.status === 403) {
          noty({
            text: jqXHR.responseJSON.message,
            type: 'error',
            layout: 'topCenter',
            modal: true
          });
        }
        return error;
      } else {
        return error;
      }
    }
  });

});