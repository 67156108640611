define('su-ui/routes/users/edit', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, Ember, AuthenticatedRouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {
    model: function model(params) {
      var store = this.store;
      return Ember['default'].RSVP.hash({
        user: store.find('user', params.id)
      });
    },
    setupController: function setupController(controller, models) {
      this.send('setPageTitle', 'Edit User');
      this.send('setPageSubTitle', null);
      controller.set('user', models.user);
    },
    renderTemplate: function renderTemplate() {
      this.render('users/userform');
    },
    actions: {
      willTransition: function willTransition() {
        var models = this.get('currentModel');
        if (models.user.get('isDirty')) {
          models.user.rollback();
          models.users.unloadRecord();
        }
      },
      refreshPage: function refreshPage() {
        this.refresh();
      }
    }
  });

});