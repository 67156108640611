define('su-ui/routes/hosts/add', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, Ember, AuthenticatedRouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {
    model: function model() {
      var store = this.store;
      return Ember['default'].RSVP.hash({
        host: store.createRecord('host')
      });
    },
    setupController: function setupController(controller, models) {
      this.send('setPageTitle', 'Add Host');
      this.send('setPageSubTitle', null);
      controller.set('host', models.host);
    },
    renderTemplate: function renderTemplate() {
      this.render('hosts/hostform');
    },
    actions: {
      willTransition: function willTransition() {
        var models = this.get('currentModel');
        if (models.host.get('isDirty')) {
          models.host.rollback();
          models.host.unloadRecord();
        }
      }
    }
  });

});