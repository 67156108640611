define('su-ui/controllers/users/edit', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    needs: ['application'],

    // Set the validation errors from the json adapter
    validationErrors: null,
    updateValidationErrors: (function () {
      this.controllerFor('application').set('validationErrors', this.get('user.errors.messages'));
    }).observes('user.errors.messages'),

    isNew: false,
    statuses: [{
      'id': 'N',
      'title': 'No'
    }, {
      'id': 'Y',
      'title': 'Yes'
    }],
    actions: {
      save: function save() {
        var self = this;
        console.log('Editing user');
        var user = this.get('user');
        user.save().then(function () {
          noty({
            text: 'User updated successfully!'
          });
          self.transitionToRoute('users.index');
        }, function (error) {
          console.log(error);
          noty({
            text: 'Error updating user!',
            type: 'error'
          });
        });
      },
      reset: function reset() {
        this.transitionToRoute('users.index');
      }
    }
  });

});