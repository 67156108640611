define('su-ui/controllers/users/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    actions: {
      deleteUser: function deleteUser(user) {
        console.log("Deleting user: " + user.id);
        var sure = confirm("Delete user " + user.get("email"));
        if (sure) {
          user.destroyRecord().then(function () {
            noty({
              text: "User deleted successfully!"
            });
          }, function (error) {
            console.log(error);
            noty({
              text: "Error deleting user!"
            });
          });
        }
      }
    }
  });

});