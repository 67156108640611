define('su-ui/authorizers/custom', ['exports', 'ember', 'simple-auth/authorizers/base'], function (exports, Ember, Base) {

  'use strict';

  exports['default'] = Base['default'].extend({
    authorize: function authorize(jqXHR) {
      var session = this.get('session');
      if (session.isAuthenticated && !Ember['default'].isEmpty(session.get('secure.token'))) {
        jqXHR.setRequestHeader('token', this.get('session.secure.token'));
      }
    }
  });

});