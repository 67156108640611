define('su-ui/routes/hosts/index', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, Ember, AuthenticatedRouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {
    model: function model() {
      var store = this.store;
      return Ember['default'].RSVP.hash({
        hosts: store.find('host')
      });
    },
    setupController: function setupController(controller, models) {
      this.send('setPageTitle', 'Hosts');
      this.send('setPageSubTitle', {
        title: 'Add Host',
        action: 'hosts.add'
      });
      controller.set('hosts', models.hosts);
    }
  });

});