define('su-ui/controllers/hosts/index', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    actions: {
      deleteHost: function deleteHost(host) {
        console.log("Deleting host: " + host.id);
        var sure = confirm("Delete host " + host.get("display_name"));
        if (sure) {
          host.destroyRecord().then(function () {
            noty({
              text: "Host deleted successfully!"
            });
          }, function (error) {
            console.log(error);
            noty({
              text: "Error deleting host!"
            });
          });
        }
      }
    }
  });

});