define('su-ui/models/user', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    email: DS['default'].attr('string'),
    first_name: DS['default'].attr('string'),
    last_name: DS['default'].attr('string'),
    password: DS['default'].attr('string'),
    active: DS['default'].attr('string'),
    created_ts: DS['default'].attr('timestamp')
  });

});