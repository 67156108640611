define('su-ui/transforms/timestamp', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Transform.extend({
		serialize: function serialize(value) {
			return value;
		},
		deserialize: function deserialize(value) {
			return moment(value, "X").format();
		}
	});

});