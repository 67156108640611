define('su-ui/models/hosthistory', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    hostdetail_id: DS['default'].attr('string'),
    status: DS['default'].attr('string'),
    created_ts: DS['default'].attr('timestamp')
  });

});