define('su-ui/controllers/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    actions: {
      logout: function logout() {
        var session = this.get('session');
        session.invalidate();
      }
    }
  });

});