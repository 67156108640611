define('su-ui/controllers/profile', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    needs: ['application'],

    // Set the validation errors from the json adapter
    validationErrors: null,
    updateValidationErrors: (function () {
      this.controllerFor('application').set('validationErrors', this.get('user.errors.messages'));
    }).observes('user.errors.messages'),

    actions: {
      save: function save() {
        console.log('Editing user');
        var user = this.get('user');
        user.save().then(function () {
          noty({
            text: 'Profile updated successfully!'
          });
          // self.transitionToRoute('users.index');
        }, function (error) {
          console.log(error);
          noty({
            text: 'Error updating profile!',
            type: 'error'
          });
        });
      },
      reset: function reset() {
        this.transitionToRoute('index');
      }
    }
  });

});