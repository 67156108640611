define('su-ui/router', ['exports', 'ember', 'su-ui/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.map(function () {
    this.route('login');
    this.route('profile');
    this.route('forgot');
    this.route('reset', {
      path: '/reset/:token'
    });
    this.resource('hosts', function () {
      this.route('add');
      this.route('edit', {
        path: '/edit/:id'
      });
    });
    this.resource('users', function () {
      this.route('add');
      this.route('edit', {
        path: '/edit/:id'
      });
    });
  });

  exports['default'] = Router;

});