define('su-ui/routes/reset', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model(params) {
      this.controllerFor('reset').set('token', params.token);
    },
    setupController: function setupController() {}
  });

});