define('su-ui/models/hostdetail', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    host_id: DS['default'].attr('string'),
    check_service: DS['default'].attr('string'),
    port: DS['default'].attr('string'),
    status: DS['default'].attr('string'),
    created_ts: DS['default'].attr('timestamp'),

    isUp: (function () {
      return this.get('status') > 0 ? true : false;
    }).property('status')
  });

});