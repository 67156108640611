define('su-ui/controllers/forgot', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    tokenEndpoint: 'https://api.serversup.net:8080/api/forgot',

    actions: {
      sendEmail: function sendEmail() {
        var self = this;
        var email = this.get('email');
        if (email) {
          Ember['default'].$.ajax({
            url: self.tokenEndpoint,
            type: 'POST',
            data: JSON.stringify({
              email: email
            }),
            contentType: 'application/json'
          }).then(function () {
            noty({
              text: 'Password reset mail sent!'
            });
            self.transitionToRoute('login');
          }, function () {
            noty({
              text: 'Error sending password reset email!',
              type: 'error'
            });
          });
        }
      }
    }
  });

});