define('su-ui/routes/application', ['exports', 'ember', 'simple-auth/mixins/application-route-mixin'], function (exports, Ember, ApplicationRouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(ApplicationRouteMixin['default'], {
    actions: {
      setPageTitle: function setPageTitle(title) {
        this.controllerFor('application').set('pageTitle', title);
      },
      setPageSubTitle: function setPageSubTitle(subTitle) {
        this.controllerFor('application').set('pageSubTitle', subTitle);
      },
      sessionAuthenticationFailed: function sessionAuthenticationFailed(error) {
        noty({
          text: error,
          type: 'error'
        });
      },
      sessionAuthenticationSucceeded: function sessionAuthenticationSucceeded() {
        var session = this.get('session.content.secure');
        var value = 'email=' + session.remember_me ? encodeURIComponent(session.email) : '';
        var path = '; path=/';
        var expires = '; expires=2147483647 ';
        document.cookie = value + path + expires;
        this.transitionTo('index');
      }
    }
  });

});