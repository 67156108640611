define('su-ui/routes/index', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, Ember, AuthenticatedRouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {
    model: function model() {
      var store = this.store;
      return Ember['default'].RSVP.hash({
        hosts: store.find('host')
      });
    },
    setupController: function setupController(controller, models) {
      var self = this;
      var hosts = models.hosts;
      controller.set('hosts', hosts);

      hosts.forEach(function (host) {
        console.log(host);
        self.store.find('hostdetail', {
          host_id: host.get('id')
        }).then(function (data) {
          host.set('hostdetails', data);
        }, function (err) {
          console.log(err);
        });
      });

      this.send('setPageTitle', 'Dashboard');
    }
  });

});