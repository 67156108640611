define('su-ui/controllers/reset', ['exports', 'ember', 'simple-auth/mixins/unauthenticated-route-mixin'], function (exports, Ember, UnauthenticatedRouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(UnauthenticatedRouteMixin['default'], {
    tokenEndpoint: 'https://api.serversup.net:8080/api/reset',
    actions: {
      reset: function reset() {
        var self = this;
        var form = this.getProperties('token', 'email', 'new_password', 'confirm_password');
        if (!form.new_password) {
          noty({
            text: 'Password is required!',
            type: 'error'
          });
          return;
        }
        if (form.new_password !== form.confirm_password) {
          noty({
            text: 'Passwords do not match!',
            type: 'error'
          });
          return;
        }
        Ember['default'].$.ajax({
          url: self.tokenEndpoint,
          type: 'PUT',
          data: JSON.stringify({
            email: form.email,
            new_password: form.new_password,
            password_reset_token: form.token
          }),
          contentType: 'application/json'
        }).then(function () {
          noty({
            text: 'Password has been reset!<br>You can now log in.'
          });
          self.transitionToRoute('login');
        }, function () {
          noty({
            text: 'Error resetting password!',
            type: 'error'
          });
        });
      }
    }
  });

});