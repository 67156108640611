define('su-ui/routes/hosts/edit', ['exports', 'ember', 'simple-auth/mixins/authenticated-route-mixin'], function (exports, Ember, AuthenticatedRouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(AuthenticatedRouteMixin['default'], {
    model: function model(params) {
      var store = this.store;
      return Ember['default'].RSVP.hash({
        host: store.find('host', params.id),
        hostdetails: store.find('hostdetail', { host_id: params.id }),
        hostdetail: store.createRecord('hostdetail', {
          service_check: 'dns'
        })
      });
    },
    setupController: function setupController(controller, models) {
      this.send('setPageTitle', 'Edit Host');
      this.send('setPageSubTitle', null);
      controller.set('host', models.host);
      controller.set('hostdetails', models.hostdetails);
      models.hostdetail.set('check_service', 'dns');
      controller.set('hostdetail', models.hostdetail);
    },
    renderTemplate: function renderTemplate() {
      this.render('hosts/hostform');
    },
    actions: {
      willTransition: function willTransition() {
        var models = this.get('currentModel');
        if (models.host.get('isDirty')) {
          models.host.rollback();
          models.host.unloadRecord();
        }
      },
      refreshPage: function refreshPage() {
        this.refresh();
      }
    }
  });

});