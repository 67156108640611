define('su-ui/controllers/hosts/edit', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    needs: ['application'],

    // Set the validation errors from the json adapter
    validationErrors: null,
    updateValidationErrors: (function () {
      this.controllerFor('application').set('validationErrors', this.get('host.errors.messages'));
    }).observes('host.errors.messages'),

    isNew: false,
    services: [{
      'id': 'dns',
      'title': 'DNS',
      'port': '53'
    }, {
      'id': 'ftp',
      'title': 'FTP',
      'port': '21'
    }, {
      'id': 'http',
      'title': 'HTTP',
      'port': '80'
    }, {
      'id': 'https',
      'title': 'HTTPS',
      'port': '443'
    }, {
      'id': 'imap',
      'title': 'IMAP',
      'port': '143'
    }, {
      'id': 'imaps',
      'title': 'IMAPS',
      'port': '993'
    }, {
      'id': 'pop3',
      'title': 'POP3',
      'port': '110'
    }, {
      'id': 'pop3s',
      'title': 'POP3S',
      'port': '995'
    }, {
      'id': 'smtp',
      'title': 'SMTP',
      'port': '25'
    }, {
      'id': 'smtps',
      'title': 'SMTPS',
      'port': '587'
    }],
    serviceChanged: (function () {
      var services = this.get('services');
      var check = this.get('hostdetail.service_check');
      if (!check) {
        return;
      }
      var service = services.filter(function (s) {
        return s.id === check;
      });
      this.set('hostdetail.check_service', service[0].id);
      this.set('hostdetail.port', service[0].port);
    }).observes('hostdetail.service_check'),
    actions: {
      save: function save() {
        var host = this.get('host');
        host.save().then(function () {
          noty({
            text: 'Host updated successfully!'
          });
          //self.transitionToRoute('hosts.index');
        }, function (error) {
          console.log(error);
          noty({
            text: 'Error updating host!',
            type: 'error'
          });
        });
      },
      addDetail: function addDetail() {
        var self = this;
        var host = this.get('host');
        var hostdetail = this.get('hostdetail');

        hostdetail.set('host_id', host.get('id'));
        hostdetail.save().then(function (data) {
          var hostdetails = self.get('hostdetails');
          hostdetails.pushObject(data);
          noty({
            text: 'Check added successfully!'
          });
          self.send('refreshPage');
        }, function (error) {
          console.log(error);
          noty({
            text: 'Error adding check!',
            type: 'error'
          });
        });
      },
      deleteDetail: function deleteDetail(hDetail) {
        var self = this;
        hDetail.destroyRecord({
          host_id: this.get('host.id')
        }).then(function () {
          noty({
            text: 'Check deleted successfully!'
          });
          self.send('refreshPage');
        }, function () {
          noty({
            text: 'Error deleting check!',
            type: 'error'
          });
        });
      },
      reset: function reset() {
        this.transitionToRoute('hosts.index');
      }
    }
  });

});