define('su-ui/models/host', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    account_id: DS['default'].attr('string'),
    display_name: DS['default'].attr('string'),
    check_address: DS['default'].attr('string'),
    notify_email: DS['default'].attr('string'),
    created_ts: DS['default'].attr('timestamp')
  });

});