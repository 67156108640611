define('su-ui/authenticators/custom', ['exports', 'ember', 'simple-auth/authenticators/base'], function (exports, Ember, Base) {

  'use strict';

  exports['default'] = Base['default'].extend({
    tokenEndpoint: 'https://api.serversup.net:8080/api/auth',

    restore: function restore(data) {
      console.log('Restoring Session Data');
      return new Ember['default'].RSVP.Promise(function (resolve, reject) {
        if (!Ember['default'].isEmpty(data)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate: function authenticate(options) {
      var _this = this;
      return new Ember['default'].RSVP.Promise(function (resolve, reject) {
        Ember['default'].$.ajax({
          url: _this.tokenEndpoint,
          type: 'POST',
          data: JSON.stringify({
            email: options.email,
            password: options.password
          }),
          contentType: 'application/json'
        }).then(function (response) {
          Ember['default'].run(function () {
            resolve({
              user_id: response.id,
              token: response.token,
              email: options.email,
              first_name: response.first_name,
              last_name: response.last_name,
              remember_me: options.remember_me
            });
          });
        }, function (xhr) {
          Ember['default'].run(function () {
            var status = xhr.status;
            if (status === 401) {
              reject('Invalid Email or Password');
            } else {
              reject('Unknown Authentication Error');
            }
          });
        });
      });
    },
    invalidate: function invalidate(data) {
      console.log(data);
      var _this = this;
      return new Ember['default'].RSVP.Promise(function (resolve, reject) {
        Ember['default'].$.ajax({
          url: _this.tokenEndpoint,
          type: 'DELETE'
        }).then(function () {
          Ember['default'].run(function () {
            resolve();
          });
        }, function (xhr) {
          var response = JSON.parse(xhr.responseText);
          Ember['default'].run(function () {
            reject(response.error);
          });
        });
      });
    }
  });

});